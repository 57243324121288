<template>
  <section id="display-campaigns-list">
    <b-row>
      <b-col md="10">
        <h1>{{ $t("adsDisplay.campaigns") }}</h1>
        <h5 class="text-primary">
          {{ $t("adsDisplay.campaignsList") }}
        </h5>
      </b-col>
      <b-col style="text-align: end;">
        <b-button
          variant="primary"
          @click="createNewCampaign"
        >
          <feather-icon
            icon="FolderPlusIcon"
            class="mr-50"
          />
          <span class="text-nowrap">{{ $t('crtCamp') }}</span>
        </b-button>
      </b-col>
    </b-row>
    <b-row
      v-if="showJumbotron"
      class="mt-5"
    >
      <b-col>
        <b-jumbotron
          bg-variant="transparent"
          border-variant="primary"
          :header="$t('advertising.NotCampaignsTitle')"
        >
          <p class="text-primary">
            {{ $t("advertising.NotCampaignsDescription") }}
          </p>
        </b-jumbotron>
      </b-col>
    </b-row>
    <b-overlay
      v-else
      variant="transparent"
      :show="show"
    >
      <b-row class="mt-2 mb-2">
        <b-col md="6">
          <b-form-input
            v-model="search"
            :placeholder="$t('advertising.CampaignName')"
            type="text"
            name="search"
            @keyup="keyUp($event)"
          />
        </b-col>
      </b-row>
      <b-table
        id="table"
        class="mt-1"
        :striped="true"
        sticky-header="650px"
        :no-border-collapse="true"
        responsive="sm"
        hover
        fixed
        :items="resSearch"
        show-empty
        :fields="columns"
        @row-clicked="goToAdsList"
      >
        <template #cell(name)="data">
          <div
            id="acortarPadre"
            v-b-tooltip.hover.bottom="data.item.name"
            class="text-nowrap "
          >
            <span>{{ data.item.name }}</span>
          </div>
        </template>
        <template #cell(startDate)="data">
          <div class="text-nowrap ">
            {{ fechaFormato(data.item.startDate) }}
          </div>
        </template>
        <template #cell(finalDate)="data">
          <div class="text-nowrap ">
            {{ fechaFormato(data.item.finalDate) }}
          </div>
        </template>
        <template #cell(isActive)="data">
          <div class="text-nowrap ">
            <h4>
              <b-badge
                pill
                :variant="`light-${data.item.isActive ? 'success' : 'danger'}`"
                class="text-capitalize"
              >
                <feather-icon
                  :icon="data.item.isActive ? 'CheckIcon' : 'XIcon'"
                  size="20"
                  class="mr-50"
                />
                {{
                  data.item.isActive
                    ? $t("dataGeneric.active")
                    : $t("dataGeneric.inactive")
                }}
              </b-badge>
            </h4>
          </div>
        </template>
        <template #cell(action)="data">
          <div class="text-nowrap d-flex justify-content-around">
            <feather-icon
              icon="Edit2Icon"
              class="text-success cursor-pointer"
              size="18"
              @click.stop.prevent="routeToEditView(data.item.id)"
            />
            <feather-icon
              icon="DeleteIcon"
              class="text-danger cursor-pointer"
              size="18"
              @click.stop.prevent="deleteFolder(data.item.id)"
            />
          </div>
        </template>
      </b-table>
      <b-pagination
        v-if="rows > perPage"
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        size="lg"
        class="pt-1"
        @input="getCampaignsByFilter"
      />
    </b-overlay>
  </section>
</template>
<script>
import {
  BBadge,
  BRow,
  BCol,
  BButton,
  VBTooltip,
  BJumbotron,
  BOverlay,
  BFormInput,
  BPagination,
  BTable,
} from 'bootstrap-vue'
import axios from '@axios'
import { messageError } from '@/store/functions'

// eslint-disable-next-line import/no-extraneous-dependencies
import { isObject } from '@vueuse/shared'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BRow,
    BTable,
    BFormInput,
    BCol,
    BButton,
    BBadge,
    BJumbotron,
    BOverlay,
    BPagination,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      campaigns: [],
      resSearch: [],
      search: '',
      showJumbotron: false,
      perPage: 20,
      currentPage: 1,
      rows: null,
      contSel: null,
      camSel: null,
      show: true,
      userData: getUserData(),
      columns: [
        { key: 'name', label: this.$t('firstName'), sortable: true },
        { key: 'startDate', label: this.$t('epg.startDate'), sortable: true },
        { key: 'finalDate', label: this.$t('epg.endDate'), sortable: true },
        { key: 'isActive', label: this.$t('editContent.status'), sortable: true },
        { key: 'action', label: this.$t('message.tableHeader.action') },
      ],
    }
  },
  watch: {
    camSel() {
      this.currentPage = 1
      this.getCampaignsByFilter()
    },
  },
  async created() {
    await this.getCampaignsByFilter()
  },
  methods: {
    keyUp(event) {
      const keycode = event.keyCode || event.which
      if (keycode === 13) {
        this.currentPage = 1
        this.getCampaignsByFilter()
      }
    },
    getCampaignsByFilter() {
      const query = `
            query{
                allDisplayCampaigns (
                name:"${this.search}",
                first:${this.perPage},
                offset:${this.perPage * (this.currentPage - 1)},
                orderBy: "name",client:"${this.userData.profile.client.id}"){
                  totalCount
                    edges {
                        node {
                            id
                            name
                            isActive
                            startDate
                            finalDate
                        }
                    }
                }
            }
            `
      axios.post('', { query }).then(res => {
        messageError(res, this)

        this.campaigns = res.data.data.allDisplayCampaigns.edges
        this.rows = res.data.data.allDisplayCampaigns.totalCount

        const campaigns = res.data.data.allDisplayCampaigns.edges
        this.campaigns = []
        campaigns.forEach(element => {
          this.campaigns.push(element.node)
        })
        this.show = false
        this.resSearch = [...this.campaigns]
      })
    },
    fechaFormato(value) {
      const date = new Date(value)
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }

      return value != null
        ? new Intl.DateTimeFormat('default', options).format(date)
        : value
    },

    createNewCampaign() {
      this.$router.push('/displayAdvertising/campaigns/create')
    },
    goToAdsList(data) {
      const { id } = data
      this.$router.push({ name: 'display-ads-list', params: { campaign: id } })
    },
    deleteFolder(id) {
      this.$swal({
        title: this.$t('code.title'),
        text: this.$t('code.text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios
            .post('', {
              query: `
              mutation{
                deleteDisplayCampaign(id:"${id}") {
                  found
                  deletedId
                }
              }`,
            })
            .then(res => {
              messageError(res, this)

              this.resSearch = this.resSearch.filter(item => item.id !== id)
              this.campaigns = this.campaigns.filter(item => item.id !== id)
            })
        }
      })
    },
    routeToEditView(campaignId) {
      let id = 0
      if (isObject(campaignId)) {
        id = campaignId.id
      } else {
        id = campaignId
      }
      this.$router.push({
        name: 'display-campaigns-edit',
        params: { id },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
#campaign-list .card-body {
  padding: 1rem;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  min-height: 1px;
}

#campaign-list .card-body h4 {
  font-size: 1.286rem !important;
}

#campaign-list .card-img-top {
  /*width: 100%;
  height: 25vh;
  object-fit: cover;*/
}

#campaign-list .actions {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgb(0 15 51 / 73%);
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

#campaign-list .card img {
  /*width: 100%;
    height:  100%;
    object-fit: cover;*/
}

#campaign-list .card-body {
  padding: 0rem;
}

#campaign-list .card-body h4 {
  font-size: 1rem !important;
}

#campaign-list .card-body .actions {
  display: -webkit-flex;
  display: flex;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 2.75rem;
  padding-right: 0.34375rem;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
#acortarPadre {
  overflow: hidden;
  text-overflow: ellipsis;

  white-space: nowrap;
}
</style>
